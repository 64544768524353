export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: "right",
  },
  addButton: {
    marginLeft: "1em",
    marginTop: "0.5em",
    marginRight: "0.5em",
    color: "#FFFFFF",
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedMads: {
    position: "fixed",
    bottom: "0.5rem",
    right: "0.5rem",
    borderRadius: "5px",
    border: "1px solid #e0e0e0",
    backgroundColor: "#253036",
    fontWeight: "bold",
    color: "#FFFFFF",
    padding: "5px",
    "@media only screen and (min-device-width : 320px) and (max-device-width : 480px)":
      {
        margin: 0,
        right: 0,
      },
  },
  deleteBtnMadSelected: {
    color: "#FFFFFF",
  },
  madSelected: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  error: {
    color: theme.palette.error.main,
  },
  selectContainer: {
    width: "calc(100% - 1em)",
  },
  container: {
    position: "relative",
  },
  madContainer: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  body: {
    padding: "5px",
    position: "relative",
    height: "calc(100% - 4rem)",
  },
  photoIconContainer: {
    position: "absolute",
    bottom: "0rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  madCard: {
    width: "20rem",
    height: "27rem",
    margin: "0.5em",
    position: "relative",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
    cursor: "pointer",
    "@media only screen and (min-device-width : 320px) and (max-device-width : 480px)":
      {
        width: "75vw",
        height: "25rem",
        marginLeft: "-1em",
      },
    zIndex: "1",
  },
  zoomIndex: {
    zIndex: "99",
  },

  madCradDelete: {
    width: "20rem",
    height: "27rem",
    margin: "0.5em",
    position: "relative",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
    cursor: "pointer",
    "@media only screen and (min-device-width : 320px) and (max-device-width : 480px)":
      {
        width: "75vw",
        height: "25rem",
        marginLeft: "-1em",
      },
    backgroundColor: "#B8B8B8	",
    zIndex: "1",
  },
  commentaireContainer: {
    marginTop: ".5em",
  },
  marginBottom: {
    marginBottom: "0.5rem",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerDivInfo: {
    width: "4rem",
    height: "2rem",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  volumetrie: {
    display: "flex",
    justifyContent: "space-around",
  },
  volumetrie_info: {
    with: "2rem",
  },
  reference: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    verticalAlign: "middle",
    backgroundColor: "#253036",
    color: "#FFF",
    padding: "6px",
    fontWeight: "bold",
    height: "2rem",
    "& div > label > span > span > svg": {
      color: "white",
    },
  },
  selectCheckbox: {
    position: "absolute",
    left: "1rem",
  },
  complete: {
    position: "absolute",
    right: "1rem",
  },
  documentBtn: {
    position: "absolute",
  },
  preview_image_container: {
    display: "flex",
    flexDirection: "row",
    maxHeight: "6rem",
    width: "100%",
    flexWrap: "wrap",
  },
  preview_image: {
    maxHeight: "6rem",
    maxWidth: "6rem",
    marginRight: "0.5rem",
  },
  checkBoxInputField: {
    paddingBottom: "1.5em",
    paddingLeft: "0",
    alignSelf: "center",
  },
  section: {
    marginBottom: "3em",
  },
  hr: {
    border: "0.5px solid #18b0ff",
    width: "100%",
  },
  border: {
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    padding: "0.5em",
    marginBottom: "1.5em",
    marginTop: "1em",
  },
  borderConclusion: {
    border: "2px solid #18b0ff",
    borderRadius: "5px",
    padding: "0.5em",
    marginBottom: "1.5em",
    marginTop: "1em",
    backgroundColor: "#f2f2f2",
  },
  listeLots: {
    width: "15%",
    fontSize: "0.7rem",
  },
  longDescription: {
    width: "20%",
  },
  listeLots: {
    fontSize: "0.8rem",
  },
  zoom_image_container: {
    maxWidth: "100vw",
    zIndex: "99",
  },
  centered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});
