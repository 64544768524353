import React, { Component } from "react";

import {
  Grid,
  MenuItem,
  Typography,
  Fab,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, Delete, PhotoCameraOutlined, Settings } from "@material-ui/icons";
import CollectionCrud from "../../common/Components/CollectionCrud";
import { collectionActions } from "../../../reducers/collectionsReducer";
import { connect } from "react-redux";
import TextInput from "../../common/Components/TextInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import NumberInput from "../../common/Components/NumberInput";
import { DateInput } from "@oniti/oniti-inputs";
import MiseADispositionCss from "./css/MiseADispositionCss";
import LotMadNegoce from "../LotMadNegoce";
import DocumentPicker from "../../common/Components/DocumentPicker";
import Modal from "../../common/Components/Modal";

const LOCALSTORAGE_FILTERS_KEY = "mise-a-disposition-negoce-filters";

class MadNegoce extends Component {
  state = {
    openLotModal: false,
    filterRecherche: "",
    filterSelectedStatus: ["ouvert"],
    filterSelectedPriorities: ["all"],
    filterSelectedValidies: ["all"],
    filterSelectedType: ["mad"],
    filterSelectedSitesUuid: ["all"],
    filterSelectedDeleted: ["non"],
    viewMobile: true,
    zoomImage: null,
    temPmadUuid: null,
    openModalAlert: false,
    openModalDelete: false,
    madToDelete: null,
    localData: [],
  };

  componentDidMount() {
    const { dispatch, display_in_detail_entreprise } = this.props;

    if (display_in_detail_entreprise) {
      this.setState({ viewMobile: false, filterSelectedType: ["all"] }, () => {
        this.fetchMads();
      });
    } else {
      this.fetchMads();
    }

    collectionActions(dispatch, "users", "INDEX", {
      params: {
        commerciaux: true,
      },
    });
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }

    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  getValideOptions(withAll = false) {
    let validite = [
      { value: "all", label: "Toutes" },
      { value: true, label: "Oui" },
      { value: false, label: "Non" },
    ];

    if (!withAll) {
      validite = validite.filter((valid) => valid.value !== "all");
    }

    return validite.map((valid) => (
      <MenuItem key={valid.value} value={valid.value}>
        {valid.label}
      </MenuItem>
    ));
  }

  loadAsyncData(filters) {
    let { dispatch } = this.props;

    return collectionActions(
      dispatch,
      "mise-a-dispositions-negoce",
      "INDEX",
      {
        params: {
          entreprise_uuid: this.props.entreprise_uuid,
        },
      },
      null,
      true
    );
  }

  getHeader(mad) {
    const { classes } = this.props;
    return (
      <div className={classes.header + " " + classes.marginBottom}>
        {this.getStatutDiv(mad)}
        {this.getCommercialDiv(mad.commercial_initials)}
        {this.getToDiv(mad.tonnage)}
        {this.getDisponibleDiv(mad.is_disponible)}
        {this.getSpotRecurrentDiv(mad)}
      </div>
    );
  }

  getCommercialDiv(commercial_initials) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{commercial_initials}</div>;
  }

  getToDiv(to) {
    const { classes } = this.props;
    return <div className={classes.headerDivInfo}>{to} To</div>;
  }

  getDisponibleDiv(is_disponible) {
    const { classes } = this.props;
    return (
      <div className={classes.headerDivInfo}>
        {is_disponible ? "Dispo" : "Non dispo"}
      </div>
    );
  }

  getSpotRecurrentDiv(mad) {
    const { classes } = this.props;
    const label = mad.spot_recurrent === "spot" ? "Spot" : "Réc";

    return <div className={classes.headerDivInfo}>{label}</div>;
  }

  getCommentaire(commentaire, length = 50) {

    return (
      <textarea

              rows="6"
              style={{ marginLeft: "5px", width: "95%" }}
              placeholder="Commentaire général"
              value={commentaire}
              disabled={true}
            />
    )

    if (!commentaire) return "";
    if (commentaire.length > length) {
      return commentaire.substring(0, length) + "...";
    }
    return commentaire;
  }

  getCommentairePart(title, commentaire, length = 50) {
    if (!commentaire) return null;
    const { classes } = this.props;
    return (
      <div className={classes.commentaireContainer}>
        <div>{title}</div>
        <div>{this.getCommentaire(commentaire, length)}</div>
      </div>
    );
  }

  getImagesPreview(image) {
    const { classes } = this.props;
    return (
      <img
        className={classes.preview_image}
        src={image.url}
        alt={image.filename}
      />
    );
  }

  getStatutDiv(mad) {
    if (!mad.statut) return null;
    let color = null;
    if (mad.is_valid) {
      color = "rgb(15 143 15)";
    } else {
      color = "rgb(239 129 34)";
    }
    const label = mad.statut
      .split("-")
      .map((word) => word[0].toUpperCase())
      .join("-");
    const { classes } = this.props;
    return (
      <div
        className={classes.headerDivInfo}
        style={{
          backgroundColor: color,
        }}
        title={mad.statut}
      >
        {label}
      </div>
    );
  }

  getLotConditionnementLibelle(lots) {
    if (!lots || lots.length === 0) return null;
    return lots.map((lot) => {
      return (
        <div key={lot.uuid}>
          {lot.conditionnement_nom} / {lot.article_nom}
        </div>
      );
    });
  }

  displayMad(mad, options) {
    const { onClickEditHandler } = options;
    const { classes } = this.props;
    const { zoomImage, temPmadUuid } = this.state;

    let customClassName = mad.deleted_at ? classes.madCradDelete : classes.madCard;

    if(temPmadUuid === mad.uuid) {
      customClassName = customClassName + ' ' + classes.zoomIndex;
    }
    return (
      <>
        <div
          key={mad.uuid}
          className={customClassName}
          onClick={() => {
            this.setState({
              enableRegisterScroll: false,
            });
            onClickEditHandler(mad);
          }}
        >
          <div
            className={classes.infos}
            style={{
              height: "100%",
            }}
          >
            <div className={classes.reference + " " + classes.marginBottom}>
              <span>{mad.reference_libelle}</span>
              {mad.deleted_at ? (
                ""
              ) : (
                <Button onClick={(e) => this.onClickHandleDelete(e, mad)}>
                  <Delete style={{ color: "red" }} />
                </Button>
              )}
            </div>
            <div className={classes.listeLots}>
              {mad.fournisseur_nom}
              <div className={classes.article + " " + classes.marginBottom}>
                {mad.liste_lots}
              </div>
              <hr />
              {this.getHeader(mad)}
              <hr />
              {this.getCommentairePart("Commentaire", mad.commentaire_general)}
              <div className={classes.photoIconContainer}>
                {this.getMadImagesPreview(mad.firsts_images, mad.uuid)}
              </div>
              {this.zoomImage(zoomImage, mad.uuid)}
            </div>
          </div>
        </div>
      </>
    );
  }

  onClickHandleDelete(e, mad) {
    e.stopPropagation();
    this.setState({ openModalDelete: true, madToDelete: mad });
  }

  getMadImagesPreview(images, uuid) {
    const { classes } = this.props;
    return (
      <div className={classes.preview_image_container}>
        {images.map((image, index) => (
          <img
            key={index}
            className={classes.preview_image}
            src={image.url}
            alt={image.filename}
            onClick={(e) => this.changeImage(e, image, uuid)}
          />
        ))}
      </div>
    );
  }

  zoomImage(image, uuid) {
    const { classes } = this.props;
    const { zoomImage, temPmadUuid } = this.state;
    if (!zoomImage || temPmadUuid != uuid) return null;
    return (
      <div className={classes.centered}>
        <img
          className={classes.zoom_image_container}
          src={zoomImage.url}
          alt={zoomImage.filename}
          onClick={(e) => this.resetImagePreview(e)}
        />
      </div>
    );
  }

  resetImagePreview(e) {
    e.stopPropagation();
    this.setState({ zoomImage: null, temPmadUuid: null });
  }

  changeImage(e, image, uuid) {
    e.stopPropagation();
    this.setState({ zoomImage: image, temPmadUuid: uuid });
  }

  renderFirstDocuments(mad) {
    const { classes } = this.props;
    return mad.lots_firsts_images.map((lot, index) => {
      return (
        <div className={classes.preview_image_container}>
          {this.getImagesPreview(lot)}
        </div>
      );
    });
    // .slice(0, 3).
  }

  renderDataCustomCallback(data, options) {
    const { ccrudOptions } = this.state;
    if (!ccrudOptions) {
      this.setState({ ccrudOptions: options });
    }

    return this.listing(data, options);
  }

  listing(data, options) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.madContainer}>
        {data.map((mad) => this.displayMad(mad, options))}
      </Grid>
    );
  }

  onChangeFilterHandler(name, e) {
    let values = e.target.value;

    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState({ [name]: values }, () => {
      this.fetchMads();
      this.saveFilters();
    });
  }

  saveFilters() {
    const {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterRecherche,
      filterSelectedValidies,
      filterSelectedDeleted,
      viewMobile,
      filterSelectedType,
    } = this.state;
    const filters = {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedValidies,
      filterSelectedSitesUuid,
      filterSelectedDeleted,
      filterRecherche,
      viewMobile,
      filterSelectedType,
    };
    localStorage.setItem(LOCALSTORAGE_FILTERS_KEY, JSON.stringify(filters));
  }

  loadFilters() {
    const filters = JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY));
    if (filters) {
      this.setState(filters);
    }
  }

  fetchMads(timeout = 0) {
    const {
      filterSelectedStatus,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSelectedValidies,
      filterSelectedDeleted,
      filterSortBy,
      filterSelectedType,
    } = this.state;

    const entreprise_uuid = this.props.entreprise_uuid;

    const params = {
      filterSelectedStatus,
      filterSelectedDeleted,
      filterSelectedPriorities,
      filterSelectedValidies,
      filterSelectedSitesUuid,
      filterSortBy,
      filterSelectedType,
      entreprise_uuid,
    };
    clearTimeout(this.state.fetchRequest);
    // eslint-disable-next-line
    this.state.fetchRequest = setTimeout(() => {
      collectionActions(
        this.props.dispatch,
        "mise-a-dispositions-negoce",
        "INDEX_WITHOUT_DISPATCH",
        {
          params,
        }
      ).then((response) => {
        this.setState({ localData: response.data });
      });
    }, timeout);
  }

  getStatusOptions() {
    const status = [
      { value: "all", label: "Tous" },
      { value: "ouvert", label: "Ouvert" },
      { value: "ferme", label: "Fermé" },
    ];

    return status.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  getDeletedAtOptions() {
    const status = [
      { value: "whithTrashed", label: "Oui" },
      { value: "non", label: "Non" },
    ];

    return status.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  getBiblioMadOptions() {
    const status = [
      { value: "all", label: "Tous" },
      { value: "biblio", label: "Biblio" },
      { value: "mad", label: "MAD" },
    ];

    return status.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }

    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  getAddBtn(onClickAddHandler) {
    if (!onClickAddHandler) return null;
    const { classes } = this.props;
    return (
      <div className={classes.contentCenter}>
        <Fab
          color="primary"
          size="small"
          aria-label="Add"
          onClick={onClickAddHandler}
          className={classes.addButton}
        >
          <Add />
        </Fab>
      </div>
    );
  }

  header() {
    const { ccrudOptions } = this.state;

    const { onClickAddHandler } = ccrudOptions || {};
    const {
      filterSelectedStatus,
      filterSelectedValidies,
      filterSelectedDeleted,
      filterSelectedType,
    } = this.state;
    const { classes, madsNegoceStore, display_in_detail_entreprise } =
      this.props;

    if (display_in_detail_entreprise) {
      return (
        <Grid container>
          <Grid item lg={1} xs={12}>
            <TextInput
              label="Status"
              className={classes.selectContainer}
              margin="normal"
              name="filterSelectedStatus"
              collectionStore={madsNegoceStore}
              onChangeHandler={this.onChangeFilterHandler.bind(this)}
              fullWidth
              inputProps={{
                multiple: true,
                value: filterSelectedStatus,
              }}
              select
            >
              {this.getStatusOptions()}
            </TextInput>
          </Grid>
          <Grid item lg={1} xs={12}>
            <TextInput
              label="Type"
              className={classes.selectContainer}
              margin="normal"
              name="filterSelectedType"
              collectionStore={madsNegoceStore}
              onChangeHandler={this.onChangeFilterHandler.bind(this)}
              fullWidth
              inputProps={{
                multiple: true,
                value: filterSelectedType,
              }}
              select
            >
              {this.getBiblioMadOptions()}
            </TextInput>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Status"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedStatus"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedStatus,
            }}
            select
          >
            {this.getStatusOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Offres valides"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedValidies"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedValidies,
            }}
            select
          >
            {this.getValideOptions(true)}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Voir suppression"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedDeleted"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: false,
              value: filterSelectedDeleted,
            }}
            select
          >
            {this.getDeletedAtOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Type"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedType"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedType,
            }}
            select
          >
            {this.getBiblioMadOptions()}
          </TextInput>
        </Grid>
        {this.getFilterRecherche()}
        <Grid item lg={3} xs={12}>
          {this.getAddBtn(onClickAddHandler)}
        </Grid>
      </Grid>
    );
  }

  getFilterRecherche() {
    const { classes, madsNegoceStore } = this.props;
    const { filterRecherche, viewMobile } = this.state;
    if (!viewMobile) return null;
    return (
      <Grid item lg={2} xs={12}>
        <TextInput
          label="Recherche"
          className={classes.selectContainer}
          margin="normal"
          name="filterRecherche"
          value={filterRecherche}
          collectionStore={madsNegoceStore}
          onChangeHandler={(name, e) => {
            this.setState({ [name]: e.target.value }, () => {
              this.saveFilters();
            });
          }}
          fullWidth
        />
      </Grid>
    );
  }

  /**
   * Traite les actions remontées par la modale de création de Lot.
   *
   * À la création d'un Lot, l'action 'close' est générée avant
   * l'action 'create'.
   *
   * @param {string} action - L'identifiant de l'action.
   * @param {Lot}    lot    - L'objet Lot retourné par l'action.
   */
  handleLotModalActions(action, lot, clicknext) {
    if (action === "close") {
      this.setState({ openLotModal: false });
    }
    if (action === "create" || action === "update") {
      this.setState({ openLotModal: false }, () => {
        collectionActions(
          this.props.dispatch,
          "mise-a-dispositions-negoce",
          "RESET_DETAIL",
          null,
          (mad) => {
            this.setState({ openLotModal: true }, () => {
              this.setState({ selectedMad: mad });
            });
          }
        );
      });
    } else if (action === "update") {
      this.setState({ openLotModal: true });
    }
  }

  /**
   * Permet de charger les sites suite au changement dans le formulaire d'autocomplete
   * @param  {[type]} entreprise_uuids [description]
   * @return {[type]}                  [description]
   */
  getSitesFromUuids(entreprise_uuids) {
    collectionActions(this.props.dispatch, "etablissements", "INDEX", {
      params: { entreprise_uuids },
    });
  }

  getEtablissements(entreprise_uuid) {
    if (!this.props.etablissementsStore.list) {
      return [];
    }
    let list = this.props.etablissementsStore.list.slice(); // Pprofshallow copy
    if (entreprise_uuid) {
      list = list.filter((e) => e.entreprise_uuid === entreprise_uuid);
    }
    return list.map((e) => {
      return (
        <MenuItem value={e.uuid} key={e.uuid}>
          {e.nom}
          {!!e.ville ? " (" + e.ville.toUpperCase() + ")" : ""}
        </MenuItem>
      );
    });
  }

  generateCommercialSelect() {
    const { usersStore } = this.props;

    if (!usersStore.list) return null;
    const commerciaux = [...usersStore.list];
    return commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    });
  }

  getLabelColumnPrix(statut_achat) {
    if (statut_achat === "oui") {
      return "€/t achat";
    }
    if (statut_achat === "offre_en_cour") {
      return " €/t offre";
    }
    return "€/t estimé";
  }

  getCreateUpdateModalContent(mad, madsNegoceStore, onChangeHandler) {
    const { classes, user } = this.props;

    let {
      uuid,
      reference_libelle,
      statut,
      is_disponible,
      is_complet,
      is_analyse_dispo,
      commentaire_general,
      tonnage,
      is_valid,
      validated_at,
      valid_user_full_name,
      spot_recurrent,
      entreprise_uuid,
      commercial_uuid,
      has_document,
      deleted_at,
      is_biblio,
    } = mad;

    return (
      <>
        <Grid container className={[classes.section]}>
          <Grid container xs={12} lg={6} className={[classes.border]}>
            <Grid item xs={6} lg={6}>
              <TextInput
                label="Référence"
                value={reference_libelle}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="reference_libelle"
                onChangeHandler={onChangeHandler}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <TextInput
                label="Statut"
                value={statut}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="statut"
                onChangeHandler={onChangeHandler}
                fullWidth
                select
                disabled={deleted_at}
              >
                <MenuItem value="ouvert">Ouvert</MenuItem>
                <MenuItem value="ferme">Fermé</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={6} lg={6}>
              <TextInput
                id="commercial_uuid"
                label="Commercial"
                value={commercial_uuid}
                required={true}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="commercial_uuid"
                onChangeHandler={(name, e) => {
                  onChangeHandler(name, e);
                }}
                className={classes.selectContainer}
                fullWidth
                select
                disabled={deleted_at}
              >
                {this.generateCommercialSelect()}
              </TextInput>
            </Grid>
            <Grid container xs={6} lg={6}>
              <Grid
                item
                xs={12}
                lg={1}
                style={{ paddingTop: "1rem", alignItems: "center" }}
              >
                <CheckboxInput
                  id="is_biblio"
                  label="Biblio"
                  value={is_biblio}
                  margin="normal"
                  collectionStore={madsNegoceStore}
                  name="is_biblio"
                  onChangeHandler={onChangeHandler}
                  placement="top"
                  disabled={deleted_at}
                  style={{ justifyContent: "center" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextInput
                id="entreprise_uuid"
                label="Fournisseur"
                value={entreprise_uuid}
                required={true}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="entreprise_uuid"
                onChangeHandler={(name, e) => {
                  this.getSitesFromUuids([e.target.value]);
                  onChangeHandler(name, e);
                }}
                className={classes.selectContainer}
                type="autocomplete"
                autocompleteProps={{
                  collectionName: "entreprises",
                }}
                fullWidth
                disabled={deleted_at}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              style={{ paddingTop: "1rem", textAlign: "center" }}
            >
              {uuid ? (
                <>
                  <DocumentPicker
                    documentable_uuid={uuid}
                    documentable_type="App\Models\MiseADispositionNegoce"
                    hasDocument={has_document}
                    restrictDocumentType={["is_lot_mad_negoce"]}
                  />
                </>
              ) : (
                <>
                  <IconButton>
                    <PhotoCameraOutlined fontSize="large" color="red" />
                  </IconButton>

                  <p>Association de doc impossible avant création</p>
                </>
              )}
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container xs={4} lg={4} className={[classes.border]}>
            <Grid
              item
              xs={12}
              lg={1}
              style={{ paddingTop: "1rem", alignItems: "center" }}
            >
              <CheckboxInput
                id="is_disponible"
                label="Disponible"
                value={is_disponible}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="is_disponible"
                onChangeHandler={onChangeHandler}
                placement="top"
                disabled={deleted_at}
              />
            </Grid>
          </Grid>

          <Grid container xs={4} lg={4} className={[classes.border]}>
            <Grid xs={12} lg={3}>
              <Grid
                item
                xs={12}
                lg={12}
                className={classes.checkBoxInputField}
                style={{ paddingTop: "1rem", alignItems: "center" }}
              >
                <RadioGroup
                  name="S/R"
                  className={classes.group}
                  value={spot_recurrent}
                  onChange={(e) => {
                    onChangeHandler("spot_recurrent", e);
                  }}
                  row
                  disabled={deleted_at}
                >
                  <FormControlLabel
                    value="spot"
                    control={<Radio color="primary" />}
                    label="Spot"
                    disabled={deleted_at}
                  />
                  <FormControlLabel
                    value="recurrent"
                    control={<Radio color="primary" />}
                    label="recurrent"
                    disabled={deleted_at}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={4} lg={4} className={[classes.border]}>
            <Grid
              item
              xs={12}
              lg={1}
              style={{ paddingTop: "1rem", alignItems: "center" }}
            >
              <CheckboxInput
                id="is_analyse_dispo"
                label="Analyse dispo"
                value={is_analyse_dispo}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="is_analyse_dispo"
                onChangeHandler={onChangeHandler}
                placement="top"
                disabled={deleted_at}
              />
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container xs={12} lg={12} className={[classes.border]}>
            <Grid item xs={6} lg={2} style={{ paddingTop: "1rem" }}>
              <CheckboxInput
                id="is_complet"
                label="Complet"
                value={is_complet}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="is_complet"
                onChangeHandler={onChangeHandler}
                fullWidth
                placement="top"
                disabled={deleted_at}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <NumberInput
                id="tonnage"
                label="Tonnage"
                value={tonnage}
                margin="normal"
                collectionStore={madsNegoceStore}
                name="tonnage"
                onChangeHandler={onChangeHandler}
                fullWidth
                disabled={deleted_at}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <label htmlFor="commentaire_general" style={{ marginLeft: "5px" }}>
              Commentaire général
            </label>
            <textarea
              id="commentaire_general"
              onChange={(e) => onChangeHandler("commentaire_general", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Commentaire général"
              value={commentaire_general}
              disabled={deleted_at}
            />
          </Grid>
        </Grid>
        {mad.uuid ? (
          <>
            <hr className={classes.hr} />
            <LotMadNegoce
              actionsCallback={this.handleLotModalActions.bind(this)}
              mise_a_disposition_negoce_uuid={uuid}
              createUpdateModalNext={"Suivant"}
              //  modal={{ only: true, open: openLotModal }}
              datas={mad.lot_mad_negoces}
              pagination={false}
              search={true}
              labelPrix={this.getLabelColumnPrix(mad.statut_achat)}
            />
          </>
        ) : (
          <>
            <hr className={classes.hr} />
            <p>Association d'article impossible avant création</p>
          </>
        )}
        <hr className={classes.hr} />
        <Grid container className={[classes.section, classes.borderConclusion]}>
          <Grid item xs={3} lg={4} style={{ paddingTop: "1rem" }}>
            <CheckboxInput
              id="is_valid"
              label="Validé"
              value={is_valid}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="is_valid"
              onChangeHandler={onChangeHandler}
              fullWidth
              placement="top"
              disabled={deleted_at}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <DateInput
              id="validated_at"
              label="Le"
              format="dd/MM/yyyy"
              value={validated_at}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="validite_offre"
              onChangeHandler={onChangeHandler}
              disabled
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <TextInput
              label="Par"
              value={valid_user_full_name}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="valid_user_full_name"
              onChangeHandler={onChangeHandler}
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </>
    );
  }

  filterLocalData() {
    const { filterRecherche, viewMobile } = this.state;
    const { localData } = this.state;
    if (!filterRecherche || !viewMobile) return localData;
    return localData.filter((mad) => {
      const keys = Object.keys(mad);
      return keys.some((key) => {
        if (typeof mad[key] === "string") {
          return mad[key].toLowerCase().includes(filterRecherche.toLowerCase());
        }
        return false;
      });
    });
  }

  getCommentaireTd(commentaire, length = 20) {
    if (!commentaire) return null;
    return (
      <span
        title={commentaire}
        style={{
          cursor: "help",
        }}
      >
        {commentaire.substring(0, length)}
        {commentaire.length > length ? "..." : ""}
      </span>
    );
  }

  getCheckBox(obj, key, readOnly = false) {
    return (
      <Checkbox
        checked={obj[key]}
        color="primary"
        disabled={readOnly}
        onChange={(e) => {
          collectionActions(
            this.props.dispatch,
            "mise-a-dispositions-negoce",
            "UPDATE",
            {
              ...obj,
              [key]: e.target.checked,
            }
          ).then(() => {
            this.fetchMads();
          });
        }}
      />
    );
  }

  getCheckBoxReadOnly(obj, key) {
    return this.getCheckBox(obj, key, true);
  }

  // Retourn la date formatée
  getDate(string, options = null) {
    if (!string) return null;

    let date = new Date(string);
    options = options || {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  getCellsConfig() {
    const { classes, display_in_detail_entreprise } = this.props;
    if (display_in_detail_entreprise) {
      return [
        {
          datakey: "reference_libelle",
          title: "Référence",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "liste_lots",
          title: "Articles",
          sortable: false,
          searchable: false,
          className: classes.listeLots,
        },
        // {this.getStatutDiv(mad)}
        // {this.getCommercialDiv(mad.commercial_initials)}
        // {this.getToDiv(mad.tonnage)}
        // {this.getDisponibleDiv(mad.is_disponible)}
        // {this.getSpotRecurrentDiv(mad)}
        {
          datakey: "statut_libelle",
          title: "Statut",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getStatutDiv(data);
          },
        },
        {
          datakey: "commercial_initials",
          title: "Commercial",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "is_valid",
          title: "Valide",
          sortable: true,
          searchable: true,
          useDataKeyOnSearch: true,
          format: this.getCheckBox.bind(this),
        },
        {
          datakey: "tonnage",
          title: "Tonnage",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getToDiv(data[key]);
          },
        },
        {
          datakey: "is_disponible",
          title: "Dispo",
          sortable: true,
          searchable: true,
          format: this.getCheckBoxReadOnly.bind(this),
        },
        {
          datakey: "spot_recurrent",
          title: "Récurrence",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getSpotRecurrentDiv(data);
          },
        },

        {
          datakey: "commentaire_general",
          title: "Commentaire Général",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getCommentaireTd(data[key], 50);
          },
          className: classes.longDescription,
        },
      ];
    } else {
      return [
        {
          datakey: "reference_libelle",
          title: "Référence",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "statut_libelle",
          title: "Statut",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "description",
          title: "Description",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getCommentaireTd(data[key], 50);
          },
          className: classes.longDescription,
        },
        {
          datakey: "is_valid",
          title: "Valide",
          sortable: true,
          searchable: true,
          useDataKeyOnSearch: true,
          format: this.getCheckBox.bind(this),
        },
        {
          datakey: "spot_recurrent",
          title: "S/R",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "is_disponible",
          title: "Dispo",
          sortable: true,
          searchable: true,
          format: this.getCheckBoxReadOnly.bind(this),
        },
        {
          datakey: "statut_achat",
          title: "Achete",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "liste_lots",
          title: "Articles",
          sortable: false,
          searchable: false,
          className: classes.listeLots,
        },
        {
          datakey: "commentaire_general",
          title: "Commentaire Général",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getCommentaireTd(data[key], 50);
          },
          className: classes.longDescription,
        },
        {
          datakey: "clients_potentiels",
          title: "Clients potentiels",
          sortable: true,
          searchable: true,
          format: (data, key) => {
            return this.getCommentaireTd(data[key], 100);
          },
          className: classes.longDescription,
        },
        {
          datakey: "date_fin",
          title: "Date de fin",
          sortable: true,
          searchable: true,
          isDate: true,
          format: (obj, key) => {
            return this.getDate(obj[key]);
          },
        },
      ];
    }
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la MaD Negoce"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette mise à disposition negoce ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(mad) {
    let title = !!mad
      ? mad.reference_libelle
      : "Ajout d'une nouvelle mise à disposition negoce";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getModeView() {
    if (this.props.display_in_detail_entreprise) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={3}>
          <CheckboxInput
            label="Vue mobile"
            a
            value={this.state.viewMobile}
            onChangeHandler={(name, e) => {
              this.setState(
                { viewMobile: e.target.value, ccrudOptions: null },
                () => {
                  this.saveFilters();
                }
              );
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  actionsCallback(action, data) {
    switch (action) {
      case "delete":
      case "create":
        this.setState({ openModal: true });
      case "update":
        this.fetchMads();
        break;
      case "click-edit":
        this.getSitesFromUuids([data.entreprise_uuid]);
        break;
      default:
        break;
    }
  }

  getModalContentDelete() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <Delete className={classes.ico} /> Demande de confirmation
        </DialogTitle>
        <DialogContent key="content">
          <Typography>Confirmez vous la suppression de cette MAD</Typography>
        </DialogContent>
      </>
    );
  }

  getExtraDataForm() {
    let { entrepriseUuid } = this.props;
    if (entrepriseUuid) {
      return {
        entreprise_uuid: entrepriseUuid,
      };
    }
    return {};
  }

  onCloseHandlerModalDelete() {
    this.setState({ openModalDelete: false, madToDelete: null });
  }

  onSubmitHandlerModalDelete() {
    this.setState({ openModalDelete: false });
    collectionActions(
      this.props.dispatch,
      "mise-a-dispositions-negoce",
      "DELETE",
      this.state.madToDelete
    ).then(() => {
      this.setState({ madToDelete: null });
      this.fetchMads();
    });
  }

  getDocumentModalBtn(mad) {
    return (
      <DocumentPicker
        documentable_uuid={mad.uuid}
        documentable_type="App\Models\MiseADispositionNegoce"
        hasDocument={mad.has_document}
        restrictDocumentType={["is_lot_mad_negoce"]}
      />
    );
  }

  render() {
    const { viewMobile, openModalDelete } = this.state;

    return (
      <>
        <Typography component="div" dir={"ltr"} style={{ padding: 8 * 3 }}>
          {this.getModeView()}
          {this.header()}
          <CollectionCrud
            collectionName={"mise-a-dispositions-negoce"}
            loadDatas={true}
            datas={this.filterLocalData()}
            showBtnEdit={true}
            showBtnAdd={true}
            showBtnDelete={true}
            fullScreenModal={true}
            cancelUpdateCheck={true}
            rights={{
              create: "admin-crud-mises-a-disposition-negoce",
              edit: "admin-crud-mises-a-disposition-negoce",
              delete: "admin-crud-mises-a-disposition-negoce",
            }}
            deleteModalTitle={this.getDeleteModalTitle}
            deleteModalContent={this.getDeleteModalContent}
            createUpdateModalTitle={this.getCreateUpdateModalTitle}
            createUpdateModalContent={this.getCreateUpdateModalContent.bind(
              this
            )}
            renderDataCustomCallback={
              viewMobile ? this.renderDataCustomCallback.bind(this) : null
            }
            extradatasForm={this.getExtraDataForm()}
            defaultValues={{
              priorite: "p1",
              complet: false,
              commercial_uuid: this.props.user.uuid,
              statut: "ouvert",
            }}
            modalContentStyle={{}}
            actionsCallback={this.actionsCallback.bind(this)}
            cellsConfig={this.getCellsConfig()}
            datatableConfig={{
              showPagination: true,
              showSearch: true,
              defaultSort: "reference_libelle",
              sortType: "desc",
              nodatalabel: "Aucune Mad",
              rowsPerPageOptions: [25, 50],
            }}
            additionnalControllers={[this.getDocumentModalBtn.bind(this)]}
            disabledEnterModal={true}
            persistDatatableOptions={{
              id: "mad_negoce_list",
              exipiration_minutes: 60 * 12, // 12 Heures
            }}
          />
          <Modal
            openModal={openModalDelete}
            onCloseHandler={this.onCloseHandlerModalDelete.bind(this)}
            onSubmitHandler={this.onSubmitHandlerModalDelete.bind(this)}
            fullWidth={true}
            maxWidth={"sm"}
            children={this.getModalContentDelete()}
          />
        </Typography>
      </>
    );
  }
}

MadNegoce = withStyles(MiseADispositionCss)(MadNegoce);

MadNegoce = connect((store) => {
  return {
    madsNegoceStore: store.collections["mise-a-dispositions-negoce"],
    etablissementsStore: store.collections.etablissements,
    user: store.auth.user,
    usersStore: store.collections.users,
  };
})(MadNegoce);

export default MadNegoce;
